@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app__welcome-page {
    @apply h-screen bg-gray-200 flex justify-center items-center;

    img {
      @apply mx-auto;
    }
  }
}
