@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app__admin-settings {
    @apply bg-gray-200 block w-full;

    .settings-nav-info {
      @apply flex items-center justify-between mb-6 w-full;

      h1 {
        @apply font-semibold text-lg;
      }

      .date-picker {
        @apply bg-[#F1F0F3] z-[500] cursor-pointer transition duration-200 ease-in flex items-center justify-center px-2 rounded;

        .date {
          @apply bg-[#F1F0F3] outline-none text-sm py-2;
        }

        .calendar {
          @apply text-sm text-gray-900;
        }
      }
    }

    .heading {
      @apply font-semibold text-lg mb-2 w-full text-start;
    }

    .description {
      @apply font-normal text-sm w-full mb-4 text-start;
    }

    .tabs__section {
      @apply w-full rounded;
    }
  }
}
