@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .customer-profile {
    @apply w-full block;

    .back-button {
      @apply text-center mb-2 p-2 rounded-md hover:bg-gray-300 cursor-pointer w-fit hover:scale-105 transition-all ease-in;

      svg {
        @apply text-lg text-gray-950;
      }
    }

    .customer-summary {
      @apply w-full flex items-center justify-between mb-2;

      .customer-info {
        @apply max-w-[500px] h-fit flex items-center justify-start gap-2;

        .image {
          @apply w-fit;
        }

        .info {
          @apply flex flex-col items-start justify-evenly;

          .info-top,
          .info-bottom {
            @apply w-full block mb-2;

            p {
              @apply text-[#A498B2] text-xs;
            }

            h3 {
              @apply text-sm text-gray-900;
            }
          }
        }
      }

      .wallet-info {
        @apply bg-transparent flex items-center justify-center gap-3;

        .image {
          @apply p-2 rounded-full bg-green-200;

          svg {
            @apply text-sm text-gray-950;
          }
        }

        .info {
          @apply block text-right;

          p {
            @apply text-[#A498B2] text-xs;
          }

          h3 {
            @apply text-base text-gray-950 font-semibold;
          }
        }
      }
    }

    .customer-detail {
      @apply w-full block lg:h-full;

      .header {
        @apply w-full rounded p-2 mb-2;

        h1 {
          @apply text-base text-gray-950 font-semibold;
        }
      }

      .body {
        @apply w-full block p-2 rounded;

        .section__one,
        .section__two {
          @apply w-full grid grid-cols-2 gap-2 mb-6 text-start;

          .info {
            @apply flex flex-col items-start justify-center text-start;

            p {
              @apply text-[#A498B2] text-xs;
            }

            h3 {
              @apply text-sm text-gray-950 font-medium;
            }

            .status {
              @apply uppercase;
            }

            .active {
              @apply text-[#10B981] font-medium;
            }

            .blocked {
              @apply text-[#EF4444] font-medium;
            }

            .inactive {
              @apply text-[#3903A3] font-medium;
            }
          }
        }

        .section__three,
        .section__four,
        .section__five {
          @apply w-full grid grid-cols-2 lg:flex lg:flex-col lg:justify-between gap-2 lg:gap-6 mb-6 text-start;

          .info {
            @apply flex flex-col items-start justify-center text-start;

            p {
              @apply text-[#A498B2] text-xs;
            }

            h3 {
              @apply text-sm text-gray-950 font-medium;
            }
          }
        }
      }
    }
  }
}
