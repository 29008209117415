@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app__admin-plans {
    @apply bg-gray-200 block w-full;

    .plans-nav-info {
      @apply flex items-center justify-between mb-6 w-full;

      h1 {
        @apply font-semibold text-lg;
      }

      .date-picker {
        @apply bg-[#F1F0F3] z-[500] cursor-pointer transition duration-200 ease-in flex items-center justify-center px-2 rounded;

        .date {
          @apply bg-[#F1F0F3] outline-none text-sm py-2;
        }

        .calendar {
          @apply text-sm text-gray-900;
        }
      }
    }

    .plans-nav-message {
      @apply flex flex-col items-start justify-between border-b-2 border-[#F1F0F3] pb-6 mb-6 w-full gap-y-2;

      h3 {
        @apply font-semibold text-base;
      }

      p {
        @apply font-normal text-sm;
      }
    }

    .plans-group {
      @apply w-full block;

      .plans {
        @apply w-full rounded-md mb-12 bg-white p-2;

        .group-name {
          @apply flex items-center justify-between py-4;

          h3 {
            @apply font-semibold text-base;
          }

          .menu-button {
            @apply text-black;
          }

          div {
            .add-plan {
              @apply text-[#3903A3] text-sm hover:bg-gray-200;

              svg {
                @apply text-[#3903A3] text-sm;
              }
            }
          }
        }

        .table-section {
          @apply w-full;

          .chakra-table__container {
            @apply w-full;

            div {
              .edit-plan {
                @apply text-[#10B981] text-sm hover:bg-gray-200;

                svg {
                  @apply text-[#10B981] text-sm;
                }
              }

              .delete-plan {
                @apply text-[#EF4444] text-sm hover:bg-gray-200;

                svg {
                  @apply text-[#EF4444] text-sm;
                }
              }
            }
          }
        }
      }
    }
  }

  .chakra-modal__content {
    .chakra-modal__body {
      form {
        .input-section {
          @apply w-full flex items-center justify-between mb-2 gap-2;

          .input-group {
            @apply block w-full;

            .form-info {
              @apply flex items-center justify-between mb-1 w-full;

              label {
                @apply font-medium text-sm;
              }

              div {
                @apply font-medium text-sm text-red-600;
              }
            }

            input {
              @apply w-full outline-none border-2 rounded bg-transparent text-sm text-gray-900 mb-4 p-2;

              &::placehoder {
                @apply text-[#C8C1D1] text-sm;
              }
            }
          }
        }
      }
    }
  }
}
