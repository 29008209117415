/* importing google fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

@import './scss/SharedStyles.scss';

@import './components/Button/Button.scss';
@import './components/Card/Card.scss';

@import './containers/Admin/Home/Home.scss';
@import './containers/Admin/Passwords/EnterOTP/EnterOTP.scss';
@import './containers/Admin/Passwords/ForgotPassword/ForgotPassword.scss';
@import './containers/Admin/Passwords/ResetPassword/ResetPassword.scss';
@import './containers/Admin/PaymentSuccessful/PaymentSuccessful.scss';
@import './containers/Admin/Sidebar/Customers/Customers.scss';
@import './containers/Admin/Sidebar/Customers/CustomersGroup.scss';
@import './containers/Admin/Sidebar/Customers/CustomerProfile.scss';
@import './containers/Admin/Sidebar/Overview/Overview.scss';
@import './containers/Admin/Sidebar/Plans/Plans.scss';
@import './containers/Admin/Sidebar/Reports/Reports.scss';
@import './containers/Admin/Sidebar/Settings/Settings.scss';
@import './containers/Admin/Sidebar/Transactions/Transactions.scss';
@import './containers/Admin/Sidebar/Vendors/Vendors.scss';
@import './containers/Admin/Sidebar/Vendors/VendorsGroup.scss';
@import './containers/Admin/Sidebar/Vendors/VendorProfile.scss';
@import './containers/Admin/Sidebar/Vendors/Analytics/Customers.scss';
@import './containers/Admin/Sidebar/Vendors/Analytics/Orders.scss';
@import './containers/Admin/Sidebar/Vendors/Analytics/Products.scss';
@import './containers/Admin/Sidebar/Vendors/Analytics/Revenue.scss';
@import './containers/Admin/Sidebar/Vendors/Analytics/Transactions.scss';
@import './containers/Admin/SignIn/SignIn.scss';
@import './containers/Welcome/Welcome.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 138 80 252;

    --secondary: 26 54 93;
  }

  * {
    @apply box-border scroll-smooth;
  }

  body {
    @apply p-0 m-0 overflow-x-hidden font-raleway;
  }

  img {
    @apply transition duration-500;
  }

  a {
    @apply no-underline;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}
