@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app__admin-transactions {
    @apply bg-gray-200 block w-full;

    .transaction-nav-info {
      @apply flex items-center justify-between mb-6 w-full;

      h1 {
        @apply font-semibold text-lg;
      }

      .date-picker {
        @apply bg-[#F1F0F3] z-[500] cursor-pointer transition duration-200 ease-in flex items-center justify-center px-2 rounded;

        .date {
          @apply bg-[#F1F0F3] outline-none text-sm py-2;
        }

        .calendar {
          @apply text-sm text-gray-900;
        }
      }
    }

    .transaction-info-group {
      @apply flex flex-col lg:flex-row flex-wrap items-center justify-center lg:justify-between gap-y-6 mb-8 w-full;

      .transaction-info {
        @apply bg-white block w-full lg:w-[49%] h-[420px] p-4;

        .info-section {
          @apply flex justify-between items-center mb-4;

          .icon-info {
            @apply flex items-center justify-start gap-2;

            .icon {
              @apply rounded bg-primary p-2;

              svg {
                @apply text-base text-white;
              }
            }

            p {
              @apply text-sm font-semibold;
            }
          }

          .stats-info {
            @apply flex flex-col items-end justify-center gap-2;

            .stats {
              @apply flex items-center justify-center rounded-3xl px-2 py-1 text-[8px] font-medium gap-1;
            }

            .increase {
              @apply bg-[#A7F3D0] text-inherit;
            }

            .decrease {
              @apply bg-[#FECACA] text-inherit;
            }

            .info {
              @apply text-xs;
            }
          }
        }

        .value {
          @apply text-2xl font-semibold;
        }
      }
    }

    .transaction-transactions {
      @apply w-full;

      .control {
        @apply w-full flex items-center justify-between mb-2 border-b-2 border-b-[#9695A5] p-2 bg-white;

        h1 {
          @apply font-semibold text-lg py-1 px-4 bg-white w-full;
        }

        .search {
          @apply flex items-center justify-center bg-gray-200;

          svg {
            @apply text-[#9695A5] cursor-default;
          }

          input {
            @apply p-2 bg-transparent text-sm outline-none border-none;

            &::placeholder {
              @apply text-sm;
            }
          }
        }
      }

      .chakra-table__container {
        @apply w-full;
      }
    }

    .transaction-pagination {
      @apply w-full flex items-center justify-end;

      .pagination {
        @apply flex flex-row justify-center gap-2 w-fit list-none py-2 px-3 select-none m-auto;

        &-item {
          @apply w-8 h-8 flex items-center justify-center rounded;

          &-active {
            @apply text-gray-100 bg-gray-950 pointer-events-none transition-colors ease-linear;
          }

          &-active:hover {
            @apply bg-gray-950 transition-colors ease-linear;
          }
        }

        &-item:hover {
          @apply cursor-pointer bg-[#e1e4e7] transition-colors ease-linear;
        }

        &-item:active {
          @apply text-gray-100 bg-gray-950 pointer-events-none transition-colors ease-linear;
        }
      }
    }
  }
}
