@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .roles-permission {
    @apply bg-gray-200 block w-full;

    .horizontal {
      @apply w-full border-b-[#E4E0E8] border-b my-4;
    }

    .heading {
      @apply w-full text-start font-semibold text-base mb-2;
    }

    .description {
      @apply w-full text-start font-normal text-sm mb-2;
    }

    .modal-trigger {
      @apply mb-6;
    }

    .members {
      @apply w-full;

      .member {
        @apply flex items-center justify-between mb-4;

        .user-info {
          @apply flex items-center justify-center gap-2;

          svg {
            @apply text-gray-950;
          }

          .info {
            @apply block text-start gap-y-1;

            h3 {
              @apply w-full text-start font-semibold text-base;
            }

            p {
              @apply w-full text-start text-[#D6D1DC] font-normal text-sm mb-2;
            }
          }
        }

        .user-role {
          @apply flex items-center justify-center gap-4;

          .popover-button {
            @apply min-w-[200px] capitalize;
          }

          .remove-member {
            @apply cursor-pointer w-full text-2xl;

            svg {
              @apply w-full h-full;
            }
          }
        }
      }
    }
  }

  .chakra-modal__content {
    .chakra-modal__body {
      form {
        @apply w-full;

        .top-section {
          @apply w-full flex items-center justify-between mb-2 gap-2;

          .input-group {
            @apply block w-full;

            .form-info {
              @apply flex items-center justify-between mb-1 w-full;

              label {
                @apply font-medium text-sm;
              }

              div {
                @apply font-medium text-sm text-red-600;
              }
            }

            input {
              @apply w-full outline-none border-2 rounded bg-transparent text-sm text-gray-900 mb-4 p-2;

              &::placehoder {
                @apply text-[#C8C1D1] text-sm;
              }
            }
          }
        }

        .mid-section {
          @apply w-full mb-2 block;

          .form-info {
            @apply flex items-center justify-between mb-1 w-full;

            label {
              @apply font-medium text-sm;
            }

            div {
              @apply font-medium text-sm text-red-600;
            }
          }

          input {
            @apply w-full outline-none border-2 rounded bg-transparent text-sm text-gray-900 mb-4 p-2;

            &::placehoder {
              @apply text-[#C8C1D1] text-sm;
            }
          }
        }

        .bottom-section {
          @apply w-full mb-2;

          h3 {
            @apply w-full text-start font-semibold text-base mb-2;
          }

          p {
            @apply text-sm;
          }
        }

        .input-section {
          @apply w-full flex items-center justify-between mb-2 gap-2;

          .input-group {
            @apply block w-full;

            .form-info {
              @apply flex items-center justify-between mb-1 w-full;

              label {
                @apply font-medium text-sm;
              }

              div {
                @apply font-medium text-sm text-red-600;
              }
            }

            input,
            .dropdown {
              @apply w-full outline-none border-2 rounded bg-transparent text-sm text-gray-900 mb-4 p-2;

              &::placehoder {
                @apply text-[#C8C1D1] text-sm;
              }
            }
          }
        }
      }
    }
  }
}
