@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .vendor-profile {
    @apply w-full block;

    .back-button {
      @apply text-center mb-2 p-2 rounded-md hover:bg-gray-300 cursor-pointer w-fit hover:scale-105 transition-all ease-in;

      svg {
        @apply text-lg text-gray-950;
      }
    }

    .vendor-summary {
      @apply w-full flex items-center justify-between mb-2;

      .vendor-info {
        @apply max-w-[500px] h-fit flex items-center justify-start gap-2;

        .image {
          @apply w-fit;
        }

        .info {
          @apply flex flex-col items-start justify-evenly;

          .info-top,
          .info-middle {
            @apply w-full block mb-2;

            p {
              @apply text-[#A498B2] text-xs;
            }

            h3 {
              @apply text-sm text-gray-900;
            }
          }

          .info-bottom {
            @apply w-full block mb-2;

            p {
              @apply text-[#A498B2] text-xs;
            }

            h3 {
              @apply text-sm text-primary hover:underline transition-all ease-linear cursor-pointer;
            }
          }
        }
      }

      .wallet-info {
        @apply bg-transparent flex items-center justify-center gap-3;

        .image {
          @apply p-2 rounded-full bg-green-200;

          svg {
            @apply text-sm text-gray-950;
          }
        }

        .info {
          @apply block text-right;

          p {
            @apply text-[#A498B2] text-xs;
          }

          h3 {
            @apply text-base text-gray-950 font-semibold;
          }
        }
      }
    }

    .vendor-full-summary {
      @apply w-full flex flex-col lg:flex-row items-start justify-between gap-2;

      .store-detail {
        @apply w-full lg:max-w-[30%] block lg:h-full;

        .header {
          @apply w-full rounded p-2 mb-2;

          h1 {
            @apply text-base text-gray-950 font-semibold;
          }
        }

        .body {
          @apply w-full block p-2 rounded;

          .section__one,
          .section__two,
          .section__three,
          .section__four,
          .section__five,
          .section__six,
          .section__seven,
          .section__eight {
            @apply w-full grid grid-cols-2 gap-2 mb-6 text-start;

            .info {
              @apply flex flex-col items-start justify-center text-start;

              p {
                @apply text-[#A498B2] text-xs;
              }

              h3 {
                @apply text-sm text-gray-950 font-medium;
              }

              .status,
              .plan {
                @apply uppercase;
              }

              .active,
              .starter {
                @apply text-[#10B981] font-medium;
              }

              .blocked,
              .professional {
                @apply text-[#EF4444] font-medium;
              }

              .inactive,
              .premium {
                @apply text-[#3903A3] font-medium;
              }

              a {
                @apply text-start text-sm text-primary cursor-pointer hover:underline active:underline transition ease-in;
              }
            }
          }

          /* .section__three,
          .section__four,
          .section__five,
          .section__six {
            @apply w-full grid grid-cols-2 lg:flex lg:flex-col lg:justify-between gap-2 lg:gap-6 mb-6 text-start;

            .info {
              @apply flex flex-col items-start justify-center text-start;

              p {
                @apply text-[#A498B2] text-xs;
              }

              h3 {
                @apply text-sm text-gray-950 font-medium;
              }

              a {
                @apply text-start text-sm text-primary cursor-pointer hover:underline active:underline transition ease-in;
              }

              .plan {
                @apply uppercase;
              }

              .starter {
                @apply text-[#10B981] font-medium;
              }

              .professional {
                @apply text-[#EF4444] font-medium;
              }

              .premium {
                @apply text-[#3903A3] font-medium;
              }
            }
          } */
        }
      }

      .analytics {
        @apply block lg:h-full w-full;

        .header {
          @apply w-full rounded p-2 mb-2;

          h1 {
            @apply text-base text-gray-950 font-semibold;
          }
        }

        .tabs__section {
          @apply w-full rounded h-full;
        }
      }
    }
  }

  .chakra-modal__content {
    .chakra-modal__body {
      form {
        .input-section {
          @apply w-full flex items-center justify-between mb-2 gap-2;

          .input-group {
            @apply block w-full;

            .form-info {
              @apply flex items-center justify-between mb-1 w-full;

              label {
                @apply font-medium text-sm;
              }

              div {
                @apply font-medium text-sm text-red-600;
              }
            }

            input,
            .dropdown {
              @apply w-full outline-none border-2 rounded bg-transparent text-sm text-gray-900 mb-4 p-2;

              &::placehoder {
                @apply text-[#C8C1D1] text-sm;
              }
            }
          }
        }
      }
    }
  }
}
