@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .account-settings {
    @apply w-full block;

    .back-button {
      @apply text-center mb-2 p-2 rounded-md hover:bg-gray-300 cursor-pointer w-fit hover:scale-105 transition-all ease-in;

      svg {
        @apply text-lg text-gray-950;
      }
    }

    .admin-summary {
      @apply w-full flex items-center justify-start mb-2;

      .admin-info {
        @apply max-w-[500px] h-fit flex items-center justify-start gap-2;

        .image {
          @apply w-fit;
        }

        .info {
          @apply flex flex-col items-start justify-evenly;

          .info-top,
          .info-bottom {
            @apply w-full block mb-2;

            p {
              @apply text-[#A498B2] text-xs;
            }

            h3 {
              @apply text-sm text-gray-900;
            }
          }
        }
      }
    }

    .update-form {
      @apply w-full mb-4;

      form {
        .input-section {
          @apply w-full flex items-center justify-between mb-2 gap-2;

          .input-group {
            @apply block w-full;

            .form-info {
              @apply flex items-center justify-between mb-1 w-full;

              label {
                @apply font-medium text-sm;
              }

              div {
                @apply font-medium text-sm text-red-600;
              }
            }

            input {
              @apply w-full outline-none border-2 rounded bg-transparent text-sm text-gray-900 mb-4 p-2;

              &::placehoder {
                @apply text-[#C8C1D1] text-sm;
              }
            }

            .password-input {
              @apply relative w-full flex items-center justify-between;

              input {
                @apply w-full outline-none border-2 rounded bg-transparent text-sm text-gray-900 mb-4 p-2;

                &::placehoder {
                  @apply text-[#C8C1D1] text-sm;
                }
              }

              svg {
                @apply absolute right-4 top-3 cursor-pointer text-sm;
              }
            }
          }
        }
      }

      .view-activity {
        @apply text-primary cursor-pointer text-sm w-full text-start my-4 hover:underline;
      }
    }
  }
}
