@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .analytics__products {
    @apply block w-full;

    .heading {
      @apply w-full text-start mb-2;
    }

    .products-group {
      @apply w-full flex flex-row flex-wrap items-center justify-between gap-y-2;

      .product {
        @apply flex flex-col items-start w-[194px] h-[215px] p-2;

        .add-to-like {
          @apply w-full relative transition-all ease-in;

          svg {
            @apply absolute right-0 top-0 cursor-pointer hover:animate-pulse;
          }
        }

        .product-image {
          @apply w-full mb-1;

          img {
            @apply w-full;
          }
        }

        .product-name {
          @apply text-sm text-gray-900 mb-2;
        }

        .price-and-cart {
          @apply flex items-center justify-between w-full;

          .price {
            @apply flex flex-col items-center justify-center;

            p {
              @apply text-[#A498B2] text-xs;
            }

            h3 {
              @apply text-sm text-gray-900;
            }
          }

          .cart {
            @apply w-fit;
          }
        }
      }
    }
  }
}
