@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .analytics__customers {
    @apply block w-full;

    .info-section {
      @apply flex justify-between items-center mb-4;

      .icon-info {
        @apply flex items-center justify-start gap-2;

        .icon {
          @apply rounded bg-primary p-2;

          svg {
            @apply text-base text-white;
          }
        }

        p {
          @apply text-sm font-semibold;
        }
      }

      .stats-info {
        @apply flex flex-col items-end justify-center gap-2;

        .stats {
          @apply flex items-center justify-center rounded-3xl px-2 py-1 text-[8px] font-medium gap-1;
        }

        .increase {
          @apply bg-[#A7F3D0] text-inherit;
        }

        .decrease {
          @apply bg-[#FECACA] text-inherit;
        }

        .info {
          @apply text-xs;
        }
      }
    }

    .value {
      @apply text-2xl font-semibold;
    }
  }
}
