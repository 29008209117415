@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .analytics__revenue {
    @apply block w-full;

    .summary {
      @apply flex flex-col flex-wrap items-start justify-between w-full mb-2;

      p {
        @apply text-[#A498B2] text-xs;
      }

      h3 {
        @apply text-sm text-gray-900;
      }
    }

    .chart {
      @apply w-full;
    }
  }
}
