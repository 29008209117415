@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .app__enter-otp {
    @apply bg-gray-200 flex flex-row justify-center items-center h-screen;

    .app__enter-otp-centered {
      @apply w-[85%] md:w-[65%] lg:w-[40%] bg-transparent flex flex-col justify-center items-center;

      img {
        @apply mb-8;
      }

      .app__enter-otp-card {
        .heading {
          @apply mb-1 text-lg font-semibold capitalize;
        }

        .description {
          @apply text-[#C8C1D1] mb-4 text-sm;
        }

        .otp-input-container {
          @apply w-full flex items-center justify-between mb-4;
        }

        .otp-input-box {
          @apply rounded outline-1 outline-primary bg-[#E4E0E8] text-sm w-8 md:w-10 h-8 md:h-10 p-3 md:p-4;
        }

        button {
          @apply bg-[#8A50FC] hover:bg-[#693dc0] w-full mb-2;
        }

        .bottom-section {
          @apply w-full flex flex-col items-center justify-around transition-all ease-in;

          h5 {
            @apply text-[#8A50FC] text-sm;
          }

          p {
            @apply text-[#C8C1D1] text-sm;
          }

          span {
            @apply text-[#8A50FC] hover:text-[#693dc0] text-sm cursor-pointer;
          }
        }
      }
    }
  }
}
