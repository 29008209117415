@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .vendors-group {
    @apply w-full flex flex-col items-center justify-around;

    .all-vendors-group,
    .active-vendors-group,
    .verified-vendors-group {
      @apply block w-full mb-4;

      .all-vendors-header,
      .active-vendors-header,
      .verified-vendors-header {
        @apply w-full flex items-center justify-between mb-2;

        h1 {
          @apply font-semibold text-lg;
        }

        .date-filters {
          @apply flex items-center justify-center gap-2;

          .reset-filter {
            @apply text-primary hover:underline text-sm cursor-pointer;
          }

          .filter-menu {
            @apply bg-[#F1F0F3] text-sm;

            .menu-button {
              @apply text-sm font-medium;
            }

            div {
              .menu-item {
                @apply text-sm hover:bg-gray-200;
              }
            }
          }

          .from-date-picker,
          .to-date-picker {
            @apply bg-[#F1F0F3] z-[500] cursor-pointer transition duration-200 ease-in flex items-center justify-center px-2 rounded;

            .date {
              @apply bg-[#F1F0F3] outline-none text-sm py-2;
            }

            .calendar {
              @apply text-sm text-gray-900;
            }
          }

          .search-input-group {
            @apply bg-transparent;

            form {
              @apply flex items-center justify-center gap-2;

              input {
                @apply bg-[#F1F0F3] text-sm outline-none p-2 text-gray-900 border-2 rounded;

                &::placehoder {
                  @apply text-[#C8C1D1] text-sm;
                }
              }

              button {
                @apply max-h-fit;
              }
            }
          }
        }
      }

      .see-all {
        @apply text-primary cursor-pointer text-sm w-full text-end mb-2;
      }

      .all-vendors,
      .active-vendors,
      .verified-vendors {
        @apply w-full flex flex-wrap items-center justify-between gap-y-2 mb-2;

        .vendors {
          @apply w-[300px] lg:w-[350px] h-fit relative flex items-center justify-between gap-2;

          .image {
            @apply w-fit;
          }

          .info {
            @apply w-full flex flex-col items-start justify-between;

            .info-top {
              @apply w-full block relative mb-2;

              p {
                @apply text-[#A498B2] text-xs;
              }

              h3 {
                @apply text-sm text-gray-900;
              }

              .option {
                @apply absolute top-0 right-0;
              }

              div {
                .block-user {
                  @apply text-[#EF4444] text-sm hover:bg-gray-200;

                  svg {
                    @apply text-[#EF4444] text-sm;
                  }
                }

                .unblock-user {
                  @apply text-[#10B981] text-sm hover:bg-gray-200;

                  svg {
                    @apply text-[#10B981] text-sm;
                  }
                }

                .activate-user {
                  @apply text-[#3903A3] text-sm hover:bg-gray-200;

                  svg {
                    @apply text-[#3903A3] text-sm;
                  }
                }

                .view-profile {
                  @apply text-gray-950 text-sm hover:bg-gray-200;

                  svg {
                    @apply text-gray-950 text-sm;
                  }
                }

                .view-storefront {
                  @apply text-gray-950 text-sm hover:bg-gray-200;

                  svg {
                    @apply text-gray-950 text-sm;
                  }
                }
              }
            }

            .info-middle {
              @apply w-full block mb-2;

              p {
                @apply text-[#A498B2] text-xs;
              }

              h3 {
                @apply text-sm text-gray-900;
              }
            }

            .info-bottom {
              @apply w-full flex items-center justify-between;

              a,
              p {
                @apply w-full text-start text-sm text-primary cursor-pointer hover:underline active:underline transition ease-in;
              }
            }
          }
        }
      }
    }
  }
}
