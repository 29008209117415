@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app__flex {
    @apply flex justify-center items-center;
  }

  .lazy-img {
    @apply blur-lg;
  }

  .head-text {
    @apply text-3xl md:text-4xl big:text-5xl leading-none font-bold tracking-tighter;
  }

  .p-text {
    @apply text-base text-left text-secondary leading-tight large:text-[1.75rem] md:leading-normal;
  }

  .bold-text {
    @apply text-lg font-semibold text-left large:text-[2rem] tracking-tight;
  }

  .app__container {
    @apply w-full min-h-screen flex flex-row;
  }

  .app__wrapper {
    @apply w-full flex-col py-2 px-4 big:pt-16;
  }
}
