@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .app__sign-in {
    @apply bg-gray-200 flex flex-row justify-center items-center h-screen;

    .app__sign-in-centered {
      @apply w-[85%] md:w-[65%] lg:w-[40%] bg-transparent flex flex-col justify-center items-center;

      img {
        @apply mb-8;
      }

      .app__sign-in-card {
        .heading {
          @apply mb-1 text-lg font-semibold capitalize;
        }

        .description {
          @apply text-[#C8C1D1] mb-4 text-sm;
        }

        form {
          @apply w-full;

          .form-info {
            @apply flex items-center justify-between mb-1;

            label {
              @apply font-medium text-sm;
            }

            div {
              @apply font-medium text-sm text-red-600;
            }
          }

          .input-group {
            @apply relative w-full flex items-center justify-between;

            input {
              @apply w-full outline-none border-2 rounded bg-transparent text-sm text-gray-900 mb-4 p-2;

              &::placehoder {
                @apply text-[#C8C1D1] text-sm;
              }
            }

            svg {
              @apply absolute right-4 top-3 cursor-pointer text-sm;
            }
          }

          button {
            @apply bg-[#8A50FC] hover:bg-[#693dc0] w-full mb-2;
          }
        }

        .bottom-section {
          @apply w-full flex items-center justify-between transition-all ease-in;

          p {
            @apply text-[#8A50FC] hover:text-[#693dc0] text-sm cursor-pointer;
          }
        }
      }
    }
  }
}
