@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .analytics__orders {
    @apply block w-full;

    .summary {
      @apply flex items-center justify-between mb-4;

      .order-today,
      .pending-orders,
      .delivered-orders,
      .cancelled-orders {
        @apply flex flex-col flex-wrap items-center justify-between;

        .heading {
          @apply text-xs text-[#A498B2] mb-2;
        }

        .value {
          @apply text-lg text-gray-900;
        }

        .stats {
          @apply flex items-center justify-center rounded-3xl p-1 text-[8px] font-medium gap-1;

          p {
            @apply text-[10px] font-medium;
          }
        }

        .increase {
          @apply bg-[#A7F3D0] text-inherit;
        }

        .decrease {
          @apply bg-[#FECACA] text-inherit;
        }
      }

      .vertical {
        @apply h-[40px] border-l-[#A498B2] border-l-2;
      }
    }

    .chart {
      @apply w-full;
    }
  }
}
