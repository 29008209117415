@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .btn {
    @apply block outline-none border-none cursor-pointer transition-all ease-in rounded;
  }

  .btn--mobile {
    @apply w-full;
  }

  .btn--primary {
    @apply text-white font-medium;
  }

  .btn--secondary {
    @apply text-white font-medium;
  }

  .btn--medium {
    @apply w-[205px] py-2 px-5;
  }

  .btn--large {
    @apply py-2 px-5 w-full;
  }

  .btn--alpha {
    @apply bg-[#8A50FC] hover:bg-[#693dc0] cursor-pointer;
  }

  .btn--beta {
    @apply bg-[#10B981] hover:bg-[#0d9266] cursor-pointer;
  }
}
