@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .payment-method {
    @apply bg-gray-200 block w-full;

    .heading {
      @apply w-full text-start text-sm text-gray-900 font-semibold;
    }

    .methods {
      @apply h-full w-full flex items-center justify-center gap-4;

      .method {
        @apply w-[196px] h-[139px] rounded-lg cursor-pointer transition ease-linear hover:scale-105 border-none flex flex-col items-center justify-center gap-y-3 p-2;

        svg,
        p {
          @apply text-gray-500 w-full h-full;
        }

        p {
          @apply text-base;
        }
      }

      .active {
        @apply border border-solid border-primary transition ease-linear bg-white;

        svg,
        p {
          @apply text-gray-950;
        }
      }
    }
  }
}
