@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app__error {
    @apply bg-white w-full h-screen;

    .error {
      @apply max-w-lg;
    }
  }
}
