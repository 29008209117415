@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .admins-group {
    @apply bg-gray-200 block w-full;

    .all-admins-group {
      @apply block w-full mb-4;

      .admins-header {
        @apply w-full flex items-center justify-between mb-2;

        h1 {
          @apply font-semibold text-lg;
        }

        .see-all {
          @apply text-primary cursor-pointer text-sm mb-2;
        }
      }

      .all-admins {
        @apply w-full flex flex-wrap items-center justify-start gap-4 mb-2;

        .admins {
          @apply w-[300px] lg:w-[320px] h-fit relative flex items-center justify-between gap-2;

          .image {
            @apply w-fit;
          }

          .info {
            @apply w-full flex flex-col items-start justify-between;

            .info-top {
              @apply w-full block relative mb-2;

              p {
                @apply text-[#A498B2] text-xs;
              }

              h3 {
                @apply text-sm text-gray-900;
              }

              .option {
                @apply absolute top-0 right-0;
              }

              div {
                .account-settings {
                  @apply text-[#3903A3] text-sm hover:bg-gray-200;

                  svg {
                    @apply text-[#3903A3] text-sm;
                  }
                }

                .block-admin {
                  @apply text-[#EF4444] text-sm hover:bg-gray-200;

                  svg {
                    @apply text-[#EF4444] text-sm;
                  }
                }

                .unblock-admin {
                  @apply text-[#10B981] text-sm hover:bg-gray-200;

                  svg {
                    @apply text-[#10B981] text-sm;
                  }
                }
              }
            }

            .info-bottom {
              @apply w-full block mb-2;

              p {
                @apply text-[#A498B2] text-xs;
              }

              h3 {
                @apply text-sm text-gray-900;
              }
            }
          }
        }
      }
    }
  }
}
