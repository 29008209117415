@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .analytics__transactions {
    @apply block w-full;

    .heading {
      @apply flex items-end justify-between mb-2;

      h1 {
        @apply font-semibold text-lg;
      }

      p {
        @apply text-primary cursor-pointer text-sm;
      }
    }

    .table {
      @apply w-full;

      .chakra-table__container {
        @apply w-full;
      }
    }
  }
}
