@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app__admin-overview {
    @apply bg-gray-200 block w-full;

    .overview-nav-info {
      @apply flex items-center justify-between mb-6 w-full;

      h1 {
        @apply font-semibold text-lg;
      }

      .date-filters {
        @apply flex items-center justify-center gap-2;

        .reset-filter {
          @apply text-primary hover:underline text-sm cursor-pointer;
        }

        .from-date-picker,
        .to-date-picker {
          @apply bg-[#F1F0F3] z-[500] cursor-pointer transition duration-200 ease-in flex items-center justify-center px-2 rounded;

          .date {
            @apply bg-[#F1F0F3] outline-none text-sm py-2;
          }

          .calendar {
            @apply text-sm text-gray-900;
          }
        }
      }
    }

    .overview-info-group {
      @apply flex flex-col lg:flex-row flex-wrap items-center justify-center lg:justify-between gap-y-6 mb-8 w-full;

      .overview-info {
        @apply bg-white block w-full lg:w-[49%] h-[420px] p-4;

        .info-section {
          @apply flex justify-between items-center mb-4;

          .icon-info {
            @apply flex items-center justify-start gap-2;

            .icon {
              @apply rounded bg-primary p-2;

              svg {
                @apply text-base text-white;
              }
            }

            p {
              @apply text-sm font-semibold;
            }
          }

          .stats-info {
            @apply flex flex-col items-end justify-center gap-2;

            .stats {
              @apply flex items-center justify-center rounded-3xl px-2 py-1 text-[8px] font-medium gap-1;
            }

            .increase {
              @apply bg-[#A7F3D0] text-inherit;
            }

            .decrease {
              @apply bg-[#FECACA] text-inherit;
            }

            .info {
              @apply text-xs;
            }
          }
        }

        .value {
          @apply text-2xl font-semibold;
        }
      }
    }

    .overview-transactions {
      @apply w-full;

      h1 {
        @apply font-semibold text-lg py-1 px-4 bg-white w-full;
      }

      .chakra-table__container {
        @apply w-full;
      }
    }
  }
}
