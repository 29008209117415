@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app__admin-home {
    @apply bg-gray-200 flex justify-between items-start;

    .app_admin-home-sidebar {
      @apply min-w-[200px] max-w-[35%] min-h-screen block px-4 pt-6 pb-4 bg-gray-200;

      .sidebar-menu {
        @apply my-[6.5rem] flex flex-col justify-center items-start gap-4 w-full;

        .menu-item {
          @apply flex flex-row justify-start items-center p-2 gap-2 cursor-pointer hover:bg-primary text-[#A498B2] text-sm hover:text-white transition ease-in w-full rounded;
        }

        .active {
          @apply text-white bg-primary;
        }
      }

      .logout {
        @apply flex justify-start items-center p-2 gap-2 cursor-pointer hover:bg-primary text-[#A498B2] text-sm hover:text-white transition ease-in w-full rounded;
      }
    }

    .app_admin-home-main {
      @apply w-full flex flex-col items-center justify-center gap-4 bg-gray-200 p-4;

      .main-nav {
        @apply w-full;

        .nav-search {
          @apply mb-2 w-full flex items-center justify-between bg-white py-1 px-2 rounded shadow shadow-black/10 gap-2;

          .input-search {
            @apply w-[80%] flex items-center justify-between;

            .search {
              @apply flex items-center justify-center;

              svg {
                @apply text-[#9695A5] cursor-default;
              }

              input {
                @apply p-2 bg-transparent text-sm outline-none border-none;

                &::placeholder {
                  @apply text-sm;
                }
              }
            }

            .notification {
              @apply w-fit cursor-pointer;

              svg {
                @apply text-primary;
              }
            }
          }

          .avatar-info {
            @apply w-[20%] flex items-center justify-center gap-2;

            .avatar {
              @apply w-fit;
            }

            .info {
              @apply w-fit flex flex-col items-start justify-center gap-2;

              h2 {
                @apply font-semibold text-sm text-gray-950;
              }

              svg {
                @apply cursor-pointer;
              }

              p {
                @apply text-[#9695A5] text-xs;
              }
            }
          }
        }
      }

      .main-content {
        @apply w-full;
      }
    }
  }
}
