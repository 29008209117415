@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app__error-ui {
    @apply bg-secondary w-full h-screen;

    .error {
      @apply max-w-lg;

      h3 {
        @apply text-4xl big:text-5xl text-[#442B72] font-semibold;
      }

      pre {
        @apply text-white;
      }
    }
  }
}
