@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .app__payment-successful {
    @apply bg-gray-200 flex flex-row justify-center items-center h-screen;

    .app__payment-successful-centered {
      @apply w-[85%] md:w-[65%] lg:w-[40%] bg-transparent flex flex-col justify-center items-center;

      img {
        @apply w-[50%] mb-8;
      }

      .heading {
        @apply mb-1 text-lg font-semibold capitalize;
      }

      .description {
        @apply text-sm text-gray-900 max-w-[50%] text-center mb-8;
      }

      button {
        @apply bg-[#10B981] hover:bg-[#0d9266] cursor-pointer;
      }
    }
  }
}
